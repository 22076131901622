function getCroppedPhoto(cropper)
{
    var data = cropper.getCroppedCanvas({
        width: 100, height: 100
    }).toDataURL();
    document.getElementById('cropped-photo').src = data;

    $('[name="photo"]').val(data.replace(/^data\:image\/\w+\;base64\,/, ''));
}

var resizeUploadImage = new Cropper(document.getElementById('resize-upload-image'), {
    crop: function (e) {
        getCroppedPhoto(resizeUploadImage);
    },
    dragMode: 'move',
    aspectRatio: 1,
    autoCropArea: 0.65,
    restore: false,
    guides: false,
    center: false,
    highlight: false,
    cropBoxMovable: false,
    cropBoxResizable: false,
    toggleDragModeOnDblclick: false,
    background: false,
});
var resizeWebcamImage = new Cropper(document.getElementById('resize-webcam-image'), {
    crop: function (e) {
        getCroppedPhoto(resizeWebcamImage);
    },
    dragMode: 'move',
    aspectRatio: 1,
    autoCropArea: 0.65,
    restore: false,
    guides: false,
    center: false,
    highlight: false,
    cropBoxMovable: false,
    cropBoxResizable: false,
    toggleDragModeOnDblclick: false,
    background: false,
});

$(".input-image").change(function () {
    if (this.files && this.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            resizeUploadImage.replace(e.target.result);
        }

        reader.readAsDataURL(this.files[0]);
    }
});

Webcam.set({
    width: 412,
    height: 309,
    dest_width: 640,
    dest_height: 480,
    image_format: 'jpeg',
    jpeg_quality: 90,
    force_flash: false,
    flip_horiz: true,
    fps: 45
});

if (document.getElementById('my_camera'))
    Webcam.attach('#my_camera');

function take_snapshot(name) {
    Webcam.snap(function (data_uri) {
        resizeWebcamImage.replace(data_uri);
    });
}
